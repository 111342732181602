import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../../components/layout'
import Hero from '../../components/hero-image'
import HeroBar from '../../components/hero-bar'
import ProductOptions from '../../components/product-options'
import ProductFeatures from '../../components/product-features'

import WeatherSeals from './feature-dual-weather-seals.png'
// import EgressHinges from './feature-egress-hinges.png'
// import MultiPoint from './feature-multi-point-locking.png'
import ToughenedGlass from './feature-24mm-glazing.png'

const French = () => (
  <Layout>
    <Helmet title="French Doors | Stylish and fashionable | Rudd Joinery">
      <meta name="description" content="Our hand-crafted French doors combine the elegance inherent in a French door with the performance features of today whether you decide to have full height glass or one of our panel and glass variations, these doors can really make a feature of your property to give a practical entry onto your garden or patio" />      
    </Helmet>
    <Hero heroImage="french-door" heroId="hero" nextId="description">
      <h1>French</h1>
      <h2>Stylish and fashionable</h2>
    </Hero>
    <div className="section" id="description">
      <div className="wrapper">
        <div
          className="story text-block"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            French doors have remained fashionable over the years and our
            hand-crafted <strong>French</strong> doors combine the elegance inherent in a French
            door with the performance features of today whether you decide to
            have full height glass or one of our panel and glass variations.
            These doors can really make a feature of your property to give a
            practical entry onto your garden or patio.
          </p>
          <p>
            As with all our products our timber french doors are made as bespoke
            items so you can fully customise your French doors to suit the style
            of your property. Choose from different height glass, which door
            opens first and whether they're inward or outward opening and
            customize every last detail from hinges to handles.
          </p>
        </div>
      </div>
    </div>
    <ProductFeatures>
      <div className="cta-bar sticky ovalroomblue">
        <div className="expanded">
          <h3>Our french door features</h3>
          <ul>
            <li>Dual high performance weather seals</li>
            {/* <li>Multi-point espag Locking</li>
            <li>Performance butt hinges</li> */}
            <li>24mm toughened argon filled double glazing</li>
          </ul>
        </div>
      </div>
      <div className="feature-bar">
        <div className="block c__5">
          <h2>Dual weather seals</h2>
          <p>
            Featuring dual weather seals to further prevent weather ingress in
            exposed locations.
          </p>
        </div>
        <img src={WeatherSeals} alt="Dual weather seals" className="c__2"  />
      </div>
      {/* <div className="feature-bar lightgrey">
        <div className="block c__1" />
      </div><div className="feature-bar">
        <div className="block c__1" />
      </div> */}
      <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>24mm toughened double glazing</h2>
          <p>
            High energy efficiency is further increased by argon filled double
            glazing.
          </p>
        </div>
        <img src={ToughenedGlass} alt="24mm toughened double glazing" className="c__5" />
      </div>
      <div className="cta-bar ovalroomblue">
        <div className="expanded">
          <p>
            Our <strong>French</strong> doors can be installed alongside our
            other products as all our mouldings are complimented across our
            product range
          </p>
        </div>
      </div>
    </ProductFeatures>
    <HeroBar imageName="french-door" />
    <div className="section ovalroomblue">
      <div className="wrapper">
        <div className="story text-block">
          <h2 className="heading">French door options</h2>
        </div>
        <ProductOptions ProductName="French Door" />
      </div>
    </div>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">French door</h2>
          <p>
            Our bespoke <strong>French</strong> door can be
            manufactured to your specific requirements. If you need help, please
            do not hesitate to contact us on <strong>01485 529136</strong> or
            fill out our <Link to="/contact-us#contact-us">contact form</Link>.
          </p>
          <p>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default French